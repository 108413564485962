// @ts-nocheck
/* eslint-disable */

"use client";

import { useEffect, useLayoutEffect, useMemo } from "react";
import { usePathname, useSearchParams } from "next/navigation";

import onAppearAnimations from "@/lib/helpers/animations";
import { useGlobalContext } from "@/lib/globalContext";

const NavigationEvents: any = ({
  translatedURI,
  acf,
}: {
  translatedURI: Record<string, string>;
  acf?: Record<string, string>;
}) => {
  const { lenis } = useGlobalContext();

  //this breaks website without memo (debug); try to have another way to do this.
  // useMemo(() => {
  //   state.setTranslatedUri(translatedURI);
  // }, [translatedURI]);

  useLayoutEffect(() => {
    lenis?.scrollTo(0, { immediate: true, force: true });

    onAppearAnimations();

    //anchors by slug
    if (location) {
      const hash = location.pathname.slice(0, -1).split("/").pop();
      if (hash && document.getElementById(hash)) {
        document.getElementById(hash).scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    }
  }, []);

  return null;
};
export default NavigationEvents;
