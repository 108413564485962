"use client";

import { Button, Column, Flex, Heading, Icon, RichText, Row } from "tempdev-ds";
import React, { Suspense } from "react";

import Image from "next/image";
import NavigationEvents from "@/components/common/NavigationEvents/NavigationEvents";
import { useGlobalContext } from "@/lib/globalContext";

export default function NotFound() {
  const state = useGlobalContext();

  return (
    <>
      <Suspense>
        <NavigationEvents acf={{ page_style: "dark", header_absolute: true }} />
      </Suspense>
      <Row
        className="pt-10 pb-10 mt-8 mb-6 rounded-section text-center"
        style={{ backgroundColor: "var(--color-primary30)" }}
      >
        <Column>
          <Heading tag="h1" size={"h4"}>
            {state?.dict?.page404?.title}
          </Heading>
          <RichText className="mt-4">
            {state?.dict?.page404?.text}
          </RichText>
          <Button
            variant="link"
            href="/"
            rightIcon={<Icon icon="icon-arrow-link"></Icon>}
            className="mt-5"
          >
            {state?.dict?.page404.button}
          </Button>
        </Column>
      </Row>
    </>
  );
}
