export default function onAppearAnimations() {
  setTimeout(() => {
    const elementsAnimated = document.querySelectorAll(".elements_animated");
    const elementsAppear = document.querySelectorAll(".will-appear");

    let options = {
      rootMargin: "0px",
      threshold: 0.4,
    };

    // Elements Fading
    if (elementsAnimated.length > 0) {
      const removeDelay = 1200;
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (!entry.target) return;

          const element: Element = entry.target;

          if (entry.intersectionRatio > 0) {
            element.classList.add("in-viewport");
            observer.unobserve(element);
            setTimeout(() => {
              element.classList.remove("elements_animated", "in-viewport");
              if (element && element.querySelector(".elements_animated_mask")) {
                element.removeChild(element.querySelector(".elements_animated_mask") as Node);
              }
            }, removeDelay);
          }
        });
      }, options);

      [].slice.call(elementsAnimated).forEach((element) => {
        observer.observe(element);
      });
    }
    if (elementsAppear.length > 0) {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          const element = entry.target;

          if (entry.intersectionRatio > 0) {
            element.classList.add("appeared");
            observer.unobserve(element);
          }
        });
      }, options);

      [].slice.call(elementsAppear).forEach((element) => {
        observer.observe(element);
      });
    }
  }, 150);
}
